.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #ddd;
  z-index: 123;

  &.hidden {
    display: none !important;
  }

  @media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }
    .show-on-mobile {
      display: block;
    }
  }

  .customer_name span.Polaris-Text--regular {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 220px;
    height: 20px;
    display: block;
  }

  .bottom-bar-body {
    max-width: 1000px;
    margin: auto;
    padding: 5px;
  }

  .bottom-bar-header {
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 25px;
    padding: 8px;
  }
  .bottom-bar-content {
    height: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition:
      height 0.2s ease-in-out,
      box-shadow 0.3s ease-in-out;

    &.expanded {
      height: 60vh !important; // Chiều cao mới khi mở rộng
      padding: 20px 0 2rem 0;
    }
  }

  .section {
    line-height: 1.2;
  }

  .right-section {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 15px;
  }

  .big_guy {
    font-size: 1.8rem;
    color: #505050;
  }
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
  .show-on-mobile {
    display: block;
  }
}


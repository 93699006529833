@keyframes heartbeat {
  0% {
    border-color: red;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
  }
  50% {
    border-color: darkred;
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.8);
  }
  100% {
    border-color: red;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
  }
}

@keyframes rainbowAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.ticket_highlight {
  border: 3px solid red;
  border-radius: 15px;
  animation: heartbeat 0.6s ease-in-out 5;
  animation-fill-mode: forwards; // Giữ trạng thái cuối cùng của animation
}

.ticket_wrap_out {
  .ticket_element {
    cursor: pointer;
    border: 3px solid transparent;
  }

  .ticket_moving_selectable {
    &:hover {
      border: 3px dashed red;
      border-radius: 15px;
    }

    &.loading {
      background: linear-gradient(270deg, red, orange, yellow, green, cyan, blue, violet);
      background-size: 400% 400%;
      animation: rainbowAnimation 3s linear infinite;
    }
  }

  .toolchain {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 10px;
    gap: 7px;
    position: absolute;
    bottom: 5px;
    left: 16px;
    width: 100px;

    &.hidden {
      display: none;
    }

    button {
      border: 1px solid rgb(62, 62, 62);
      border-radius: 50%;
      padding: 8px 12px;
      background: transparent;
      cursor: pointer;

      &:hover {
        background-color: rgb(62, 62, 62);
        color: white;
      }
    }
  }
}


.chatGPTWrap {
  height: calc(100vh - 60px);
  width: 100%;
  background: #f9f9f9;
  position: relative;
  overflow-y: auto;

  .chatGPT_thread {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 100px;
    font-size: 15px;
    line-height: 1.5;
  }

  .message {
    max-width: 80%;
    padding: 12px 16px;
    font-size: 15px;
    line-height: 1.5;
    word-wrap: break-word;
    display: inline-block;
  }

  .user {
    color: #2003e6;
  }

  .assistant {
    align-self: flex-start;
    color: #333;
  }

  .thinking span {
    font-size: 20px;
    font-style: italic;
    font-weight: bold;
    color: gray;
    animation: blink 1.5s infinite;
  }
}

#me_talk_chatGPT {
  width: 100%;
  height: auto;
  padding: 14px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #ddd;
  font-size: 16px;
  outline: none;
  background: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}


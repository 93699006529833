.react-calendar {
  width: 100% !important;
  border-radius: 8px;

  .react-calendar__tile--now {
    background: rgba(0, 0, 0, 0.1);
    color: unset;
  }

  .react-calendar__tile--active {
    background: #006edc;
    color: white;
    border: 1px solid rgba(0, 0, 0, 0.6) !important;
  }

  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background-color: #e6e6e6;
  }

  .react-calendar__tile {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    min-height: 57px;

    & > abbr {
      font-size: 1.5rem;
      position: absolute;
      top: 10px;
      left: 8px;
    }
  }
}

.react-calendar__month-view__weekdays {
  background-color: #e6e6e6;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.lunar_title {
  display: block;
  font-size: 12px;
  position: absolute;
  right: 8px;
  bottom: 4px;
}


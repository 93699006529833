.timeslot_element {
  border: 1px solid #d0d0d0;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  padding: 4px 10px;
  z-index: 9;
  overflow: hidden;

  &.active {
    border-color: red;
  }

  .timeslot_progress_bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 25px;
    background-color: #eee;
    z-index: 8;
    overflow: hidden;

    .timeslot_progress_bar__inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: #98cef5;
      z-index: 7;

      &.notice {
        background-color: rgb(247, 188, 199);
      }
    }
  }
}

